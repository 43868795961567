import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Box, Modal, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import RocketIcon from '@mui/icons-material/Rocket'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions, Chip } from '@mui/material'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'
import { useNavigate, Link } from 'react-router-dom'
import { Global, css } from '@emotion/react'
const globalStyles = css`
  @keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0.7);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(23, 86, 163, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0);
    }
  }
`

const ModuleCompletionModal = ({ setShowModal, moduleId }) => {
  console.log('moduleId', moduleId)
  const [open, setOpen] = useState(true)
  const { width, height } = useWindowSize()

  const [assessment, setAssessment] = useState(false)

  const onClose = () => {
    setShowModal(false)
  }

  const [ready, setReady] = useState(false)
  const [resize, setResize] = useState(false)

  const ref = useRef(null)

  const [widthModal, setModalWidth] = useState(0)

  function handleResize() {
    setModalWidth(Math.round(width * 0.4))
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    setModalWidth(Math.round(width * 0.4))
    console.log('width', width)
  }, [resize, width])

  useLayoutEffect(() => {
    setModalWidth(ref.current && Math.round(ref.current.offsetWidth))
  }, [ready])

  useEffect(() => {
    if (moduleId) {
      async function getAssessment(moduleId) {
        try {
          const assessmentResponse = await axios(`/assessment/module/${moduleId}`)
          setAssessment(assessmentResponse.data)
        } catch (error) {
          console.error('Error fetching assessment:', error)
          if (error.response && error.response.status === 404) {
            setAssessment(false)
          } else {
            console.error('Error fetching assessment:', error)
            setAssessment(false)
          }
        }
      }
      getAssessment(moduleId)
    }
  }, [moduleId])

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#FFFFFF',
          border: '0',
          boxShadow: 24,
          padding: '55px',
          paddingTop: '35px',
          maxWidth: '1500px',
          minwidth: '360px',
          width: widthModal > 360 ? widthModal : 360,
          '&:focus': {
            border: '0',
            outline: 'none',
          },
        }}
      >
        <Confetti width={widthModal > 360 ? widthModal : 360} height={height} recycle={false} numberOfPieces={500} />
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} />
        <CompletionBox assessment={assessment} />
      </Box>
    </Modal>
  )
}

function CompletionBox({ assessment }) {
  const navigate = useNavigate()
  const handleReturnToDashboard = () => {
    window.location.href = '/'
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <RocketIcon sx={{ fontSize: '6rem', color: 'primary.main', marginTop: '0' }} />
      <Typography variant="h5" component="h2" sx={{ mt: 2, fontWeight: 'bold', fontSize: '2rem' }}>
        Congratulations!
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
        Well done! You have successfully completed the module.
      </Typography>
      {assessment && (
        <>
          <Divider
            flexItem
            sx={{
              margin: '40px 0 20px 0',
              borderColor: '#d9d9d9', // Change to your desired color
              '&::before, &::after': { borderColor: '#d9d9d9' }, // Color of lines on either side of the Chip
            }}
          >
            <Chip label="Next Step" size="large" />
          </Divider>

          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            Next take the Module Assessment to test your knowledge.
          </Typography>
          <Global styles={globalStyles} />
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.dark' },
              fontSize: '.8rem',
              paddingLeft: '10px',
              paddingRight: '10px',
              width: 'fit-content',
              alignSelf: 'center',
              marginTop: '20px',
              animation: 'pulseBorder 2s infinite',
            }}
            variant="contained"
            onClick={() => {
              mixpanel.track('button_clicked', {
                Feature: 'module_completion_modal_link_to_assessment',
                Location: 'Module Completion Modal',
                Name: 'Assessment from Module Completed Modal Clicked',
                Link: `/assessment/${assessment?.uuid}`,
              })
              navigate(`/assessment/${assessment?.uuid}`)
            }}
          >
            {assessment?.name}
          </Button>
        </>
      )}

      <Button size="small" color="primary" sx={{ mt: 2, fontSize: '0.8rem' }} onClick={handleReturnToDashboard}>
        Return to Dashboard
      </Button>
    </Box>
  )
}

export default ModuleCompletionModal

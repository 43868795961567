import { Box, Button, CircularProgress, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Chip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import CompanyFilter from '../users/company-filter'
import GroupFilter from '../users/group-filter'
import DeleteUser from '../users/delete-user'
import EditUser from './edit-user'
import CopyToClipboardButton from '../components/copy-to-clipboard-button'

const ManageUsers = (props) => {
  const [email, setEmail] = useState('')
  const [searchByEmail, setSearchByEmail] = useState(false)

  const [groupUuid, setGroupUuid] = useState('')
  const [searchByGroup, setSearchByGroup] = useState(false)
  const [loading, setLoading] = useState(true)
  const [companies, setCompanies] = useState([])
  const [searchedBy, setSearchedBy] = useState(null)
  const [userList, setUserList] = useState([])

  const [selectedCompanyID, setSelectedCompanyID] = useState(null)
  const [groups, setGroups] = useState([])

  useEffect(() => {
    if (searchByEmail) {
      axios({
        url: '/user/find',
        method: 'post',
        data: {
          email,
        },
      })
        .then((response) => {
          setSearchedBy('EMAIL')
          setUserList(response.data)
          setSearchByEmail(false)
        })
        .catch(() => {
          setSearchedBy('EMAIL')
          setUserList([])
          setSearchByEmail(false)
        })
    }
  }, [searchByEmail, setSearchByEmail])

  useEffect(() => {
    if (searchByGroup && selectedCompanyID) {
      const groupQueryFilter = groupUuid === '' ? '' : `?group_uuid=${groupUuid}`

      axios(`/admin/company/${selectedCompanyID}/users/${groupQueryFilter}`).then((response) => {
        setSearchedBy('GROUP')
        setUserList(response.data)
        setSearchByGroup(false)
      })
    }
  }, [searchByGroup, selectedCompanyID, groupUuid])

  const onUpdate = () => {
    if (searchedBy === 'EMAIL') {
      setSearchByEmail(true)
    } else {
      setSearchByGroup(true)
    }
  }

  useEffect(() => {
    axios('/admin/company').then((r) => {
      setCompanies(r.data)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (selectedCompanyID) {
      setLoading(true)
      axios(`/admin/company/${selectedCompanyID}/group`).then((r) => {
        setGroups(r.data)
        setLoading(false)
      })
    }
  }, [selectedCompanyID])

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ borderRight: '1px solid #CCCCCC', padding: '20px', position: 'relative' }}>
          <Typography>Find user by email:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextField label="email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Button disabled={!email} onClick={() => setSearchByEmail(true)}>
              Search
            </Button>
          </Box>
          <Typography
            sx={{
              background: '#F2F6F7',
              borderRadius: '5px',
              padding: '2px',
              position: 'absolute',
              right: '-16px',
              top: '50%',
              textAlign: 'center',
              width: '30px',
            }}
          >
            OR
          </Typography>
        </Box>
        <Box sx={{ padding: '20px' }}>
          <Typography>Show all users in Company / Group:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {!loading && <CompanyFilter filter={selectedCompanyID} setFilter={(c) => setSelectedCompanyID(c)} companies={companies} />}
            {!loading && <GroupFilter groups={groups} setFilter={setGroupUuid} />}
            <Button onClick={() => setSearchByGroup(true)}>Show Users</Button>
          </Box>
        </Box>
      </Box>
      {userList.length ? <UserList onDelete={onUpdate} onUpdate={onUpdate} userList={userList} /> : null}
    </>
  )
}

export const UserList = ({ onDelete, onUpdate, userList }) => {
  const [deleting, setDeleting] = useState(false)
  const [editing, setEditing] = useState(false)
  const [resetting, setResetting] = useState(false)

  useEffect(() => {
    if (resetting) {
      axios({
        method: 'post',
        url: `/user/${resetting}/reset-pass`,
      })
        .then((r) => {
          setResetting(false)
        })
        .catch((e) => {
          setResetting(false)
        })
    }
  }, [resetting])

  return (
    <>
      <Modal open={!!deleting} onClose={() => setDeleting(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <DeleteUser
            cancel={() => setDeleting(false)}
            onDelete={() => {
              setDeleting(false)
              onDelete(deleting)
            }}
            {...userList.find((u) => u.uuid === deleting)}
          />
        </Box>
      </Modal>
      <Modal open={!!editing} onClose={() => setEditing(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <EditUser
            onUpdate={(uuid, newData) => {
              setEditing(false)
              onUpdate(uuid, newData)
            }}
            user={userList.find((u) => u.uuid === editing)}
          />
        </Box>
      </Modal>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>username</TableCell>
            <TableCell>email</TableCell>
            <TableCell>UUID</TableCell>
            {/* <TableCell>Company</TableCell> */}
            {/* <TableCell>Groups</TableCell> */}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map((user) => (
            <UserRow
              key={user.uuid}
              loading={deleting === user.uuid || editing === user.uuid || resetting === user.uuid}
              onDelete={(userUuid) => setDeleting(userUuid)}
              onEdit={(userUuid) => setEditing(userUuid)}
              onResetPass={(userUuid) => setResetting(userUuid)}
              {...user}
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const UserRow = ({ email, loading, name, onEdit, onDelete, onResetPass, username, uuid, company_name, groups }) => {
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{username}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        <CopyToClipboardButton content={uuid} />
        {uuid}
      </TableCell>
      {/* <TableCell>{company_name}</TableCell> */}
      {/* <TableCell>
        {groups.map((group) => (
          <Chip key={group.group_uuid} label={`${group.group_name}`} />
        ))}
      </TableCell> */}
      <TableCell>
        {loading && <CircularProgress />}
        <Button onClick={() => onDelete(uuid)}>Delete</Button>
        <Button onClick={() => onEdit(uuid)}>Edit </Button>
        <Button onClick={() => onResetPass(uuid)}>Reset Credentials</Button>
      </TableCell>
    </TableRow>
  )
}

export default ManageUsers

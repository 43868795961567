import { Alert, Box, Button, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

const DeleteUser = ({ cancel, email, onDelete, name, uuid }) => {
  const [deleting, setDeleting] = useState(false)
  useEffect(() => {
    if (deleting) {
      axios({
        url: `/user/${uuid}`,
        method: 'delete',
      }).then(() => {
        setDeleting(false)
        onDelete()
      })
    }
  }, [deleting])

  return (
    <Box
      sx={{
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        padding: '40px',
        minWidth: '600px',
      }}
    >
      <Typography>Are you sure you want to delete this user from the Examfly Platform?</Typography>
      <Typography>
        <Typography sx={{ fontWeight: 'bold' }}>Name:</Typography> {name}
        <Typography sx={{ fontWeight: 'bold' }}>Email:</Typography> {email}
      </Typography>
      <Alert sx={{ marginBottom: '20px', marginTop: '20px' }} severity="error">
        <Typography>WARNING: This will delete the User from ALL groups.</Typography>
        <Typography>This will also delete all their points and progress.</Typography>
        <Typography>If you only want to remove a user from a group instead Edit User and choose a different group</Typography>
      </Alert>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button variant="contained" onClick={() => cancel()}>
          Cancel
        </Button>
        <Button onClick={() => setDeleting(true)}>Yes, I'm sure</Button>
      </Box>
    </Box>
  )
}

export default DeleteUser

import React, { useState, useEffect } from 'react'
import { Box, Button, CircularProgress, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const ManageGroup = (props) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { groupId } = useParams()
  const [loading, setLoading] = useState(true)
  const [groupData, setGroupData] = useState({})
  const [reportData, setReportData] = useState({})
  const [lessonReportData, setLessonReportData] = useState([])
  const [assessmentReportData, setAssessmentReportData] = useState([])
  const [proficiencyData, setProficiencyData] = useState([])
  const [totalPoints, setTotalPoints] = useState(null)

  useEffect(() => {
    if (loading) {
      Promise.all([
        axios(`/reports/group/${groupId}`),
        axios(`/group/${groupId}`),
        axios(`/reports/${groupId}/assessments`),
        axios(`/reports/group/${groupId}/proficiency_old`),
      ]).then(([report, groupData, assessmentReport, proficiencyReport]) => {
        setGroupData(groupData.data)
        setReportData(report.data.modulesReportData)
        setLessonReportData(report.data.lessonsReportData)
        setAssessmentReportData(assessmentReport.data.users)
        setProficiencyData(proficiencyReport.data)
        setTotalPoints(getTotalPointsFromReport(report.data.modulesReportData))
        setLoading(false)
      })
    }
  }, [groupId, loading])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Box sx={{ background: '#ffffff', padding: 5 }}>
      <Box sx={{ borderBottom: '2px solid #F2F6F7', display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
        <div>
          <Button onClick={() => setTabIndex(0)} disabled={tabIndex === 0}>
            Modules Report
          </Button>
          <Button onClick={() => setTabIndex(1)} disabled={tabIndex === 1}>
            Lessons Report
          </Button>
          <Button onClick={() => setTabIndex(2)} disabled={tabIndex === 2}>
            Assessments Report
          </Button>
          <Button onClick={() => setTabIndex(3)} disabled={tabIndex === 3}>
            Proficiency Report
          </Button>
        </div>
        <Typography sx={{ marginBottom: 1, textAlign: 'end' }} variant="h1">
          Report for: {groupData.name}
        </Typography>
      </Box>
      {tabIndex === 0 && <ModulesReport reportData={reportData} groupData={groupData} totalPoints={totalPoints} />}
      {tabIndex === 1 && <LessonsReport reportData={lessonReportData} />}
      {tabIndex === 2 && <AssessmentsReport reportData={assessmentReportData} />}
      {tabIndex === 3 && <ProficiencyReport reportData={proficiencyData} />}
    </Box>
  )
}

const User = ({ name, report, reportOrder, uuid }) => {
  const totalPoints = Object.values(report).reduce((acc, data) => acc + data.points, 0)
  return (
    <TableRow key={uuid}>
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>
      <TableCell>
        <StarIcon sx={{ '&&': { color: 'orange' } }} />
        <Typography>{totalPoints}</Typography>
      </TableCell>
      {reportOrder.map((moduleId, index) => (
        <React.Fragment key={moduleId}>
          <TableCell key={`${moduleId}-${index}`}>
            <Typography>{report[moduleId].points}</Typography>
          </TableCell>
          <TableCell key={`${uuid}-${moduleId}-${index}`}>
            <Typography>Lessons: {formatPercentage(report[moduleId].lessonsComplete, report[moduleId].lessonsTotal)}</Typography>
            <Typography>Elements: {formatPercentage(report[moduleId].elementsComplete, report[moduleId].elementsTotal)}</Typography>
          </TableCell>
        </React.Fragment>
      ))}
    </TableRow>
  )
}

const ModulesReport = ({ reportData, groupData, totalPoints }) => {
  const now = new Date()
  return (
    <>
      {Object.keys(reportData).length ? (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography component="span" sx={{ backgroundColor: '#F2F6F7', display: 'inline-flex', padding: '10px', fontWeight: 'bold' }}>
              <StarIcon sx={{ '&&': { color: 'orange' } }} />
              {totalPoints} Total points
            </Typography>
            <Link
              sx={{ marginLeft: 'auto' }}
              download={`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${groupData.name}.csv`}
              href={`data:text/plain;charset=utf-8,${encodeURIComponent(makeCSV(reportData))}`}
            >
              Download .csv
            </Link>
          </Box>
          <div style={{ overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h4">Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4">Points</Typography>
                  </TableCell>
                  {groupData.modules.map((m, index) => (
                    <React.Fragment key={`${m.uuid}-${index}`}>
                      <TableCell key={m.uuid}>
                        <Typography variant="h4">{m.name} Points</Typography>
                      </TableCell>
                      <TableCell key={m.name}>
                        <Typography variant="h4">{m.name}</Typography>
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {groupData.users.map((user) => (
                  <User key={user.uuid} {...user} report={reportData[user.uuid].byModule} reportOrder={groupData.modules.map((m) => m.uuid)} />
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      ) : (
        <Typography variant="h2" align="center">
          No reports found.
        </Typography>
      )}
    </>
  )
}

const LessonsReport = ({ reportData }) => {
  return (
    <>
      {reportData.length ? (
        <div style={{ overflow: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Link
              sx={{ marginLeft: 'auto' }}
              download={`Group Student Lesson Progress Report.csv`}
              href={`data:text/plain;charset=utf-8,${encodeURIComponent(makeLessonReportCSV(reportData))}`}
            >
              Download .csv
            </Link>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h3">Username</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">Email</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">Lesson Name</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">Lesson ID</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.map((data, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell align="center">
                      <Typography>{data.username}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{data.email}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{data.lessonName}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{data.lessonId}</Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <Typography variant="h2" align="center">
          No reports found.
        </Typography>
      )}
    </>
  )
}

const AssessmentsReport = ({ reportData }) => {
  const now = new Date()

  // Extract unique assessment names
  const assessmentNames = [...new Set(reportData.flatMap((user) => user.assessments.map((a) => a.assessmentName)))]

  return (
    <>
      {reportData.length ? (
        <div style={{ overflow: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Link
              sx={{ marginLeft: 'auto' }}
              download={`Student Assessments Report ${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}.csv`}
              href={`data:text/plain;charset=utf-8,${encodeURIComponent(makeAssessmentReportCSV(reportData))}`}
            >
              Download .csv
            </Link>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h4">Name</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h4">Email</Typography>
                </TableCell>
                {assessmentNames.map((name, index) => (
                  <React.Fragment key={index}>
                    <TableCell align="center">
                      <Typography variant="h4">{name} Result</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h4">{name} %</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h4">{name} Attempts</Typography>
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.map((user, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Typography>{user.user.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{user.user.email}</Typography>
                  </TableCell>
                  {assessmentNames.map((name, index) => {
                    const assessment = user.assessments.find((a) => a.assessmentName === name) || {}
                    return (
                      <React.Fragment key={index}>
                        <TableCell align="center">
                          <Typography>{assessment.assessmentResult || 'N/A'}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{assessment.assessmentResultPercent !== null ? `${assessment.assessmentResultPercent}%` : 'N/A'}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{assessment.attempts !== undefined ? assessment.attempts : 'N/A'}</Typography>
                        </TableCell>
                      </React.Fragment>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <Typography variant="h2" align="center">
          No reports found.
        </Typography>
      )}
    </>
  )
}

const ProficiencyReport = ({ reportData }) => {
  const now = new Date()

  return (
    <>
      {reportData.length ? (
        <div style={{ overflow: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Link
              sx={{ marginLeft: 'auto' }}
              download={`Student Proficiency Report ${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}.csv`}
              href={`data:text/plain;charset=utf-8,${encodeURIComponent(makeProficiencyReportCSV(reportData))}`}
            >
              Download .csv
            </Link>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h4">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4">Email</Typography>
                </TableCell>
                {reportData[0].modules.map((module, moduleIndex) => (
                  <React.Fragment key={moduleIndex}>
                    <TableCell>
                      <Typography variant="h4">Module</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4">Number Achieved</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4">Number Missing</Typography>
                    </TableCell>
                    {module.proficiencies.map((proficiency, proficiencyIndex) => (
                      <TableCell key={proficiencyIndex}>
                        <Typography variant="h4">{proficiency.proficiency_name}</Typography>
                      </TableCell>
                    ))}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{data.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{data.email}</Typography>
                  </TableCell>
                  {data.modules.map((module, moduleIndex) => (
                    <React.Fragment key={moduleIndex}>
                      <TableCell>
                        <Typography>{module.module_name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{module.proficienciesAchievedNumber}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{module.proficienciesMissingNumber}</Typography>
                      </TableCell>
                      {module.proficiencies.map((proficiency, proficiencyIndex) => (
                        <TableCell key={proficiencyIndex}>
                          <Typography>{proficiency.proficiency_achieved}</Typography>
                        </TableCell>
                      ))}
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <Typography variant="h2" align="center">
          No reports found.
        </Typography>
      )}
    </>
  )
}

const makeProficiencyReportCSV = (reportData) => {
  if (!reportData.length) {
    return 'No reports found.'
  }

  let CSVoutput = 'Name,Email'

  reportData[0].modules.forEach((module) => {
    CSVoutput += `,${module.module_name} - Number Achieved,${module.module_name} - Number Missing`
    module.proficiencies.forEach((proficiency) => {
      const proficiencyName = proficiency.proficiency_name.includes(',') ? `"${proficiency.proficiency_name}"` : proficiency.proficiency_name
      CSVoutput += `,${proficiencyName}`
    })
  })

  CSVoutput += '\n'

  reportData.forEach((data) => {
    let row = `${data.name},${data.email}`
    data.modules.forEach((module) => {
      row += `,${module.proficienciesAchievedNumber},${module.proficienciesMissingNumber}`
      module.proficiencies.forEach((proficiency) => {
        row += `,${proficiency.proficiency_achieved}`
      })
    })
    CSVoutput += `${row}\n`
  })

  return CSVoutput
}

function makeCSV(data) {
  const someUserId = Object.keys(data)[0]
  const moduleIds = Object.keys(data[someUserId].byModule)
  const moduleNames = moduleIds
    .reduce((acc, moduleId) => {
      const { moduleName } = data[someUserId].byModule[moduleId]
      return acc.concat([
        `${moduleName} Points`,
        `${moduleName} lessons %`,
        `${moduleName} lessons completed`,
        `${moduleName} lessons total`,
        `${moduleName} elements %`,
        `${moduleName} elements`,
      ])
    }, [])
    .join(',')

  let csv = `Name, Email, Total Points,${moduleNames}\n`

  csv += Object.entries(data)
    .map(([userId, userData]) => {
      const { name, email, byModule } = userData
      let totalPoints = 0
      const moduleStrings = moduleIds.map((moduleId) => {
        const { elementsComplete, elementsTotal, lessonsComplete, lessonsTotal, points } = byModule[moduleId]
        totalPoints += points
        return `${points}, ${formatPercentage(lessonsComplete, lessonsTotal)}, ${lessonsComplete}, ${lessonsTotal}, ${formatPercentage(
          elementsComplete,
          elementsTotal
        )},${formatNumberOfNumber(elementsComplete, elementsTotal)}`
      })
      return `${name},${email},${totalPoints},${moduleStrings}`
    })
    .join('\n')

  return csv
}

function makeLessonReportCSV(data) {
  let csv = `Username, Email, Lesson Name, Lesson ID\n`
  csv += data
    .map((row) => {
      const { username, email, lessonName, lessonId } = row
      return `${username},${email},${lessonName},${lessonId}`
    })
    .join('\n')

  return csv
}

function makeAssessmentReportCSV(data) {
  // Extract unique assessment names
  const assessmentNames = [...new Set(data.flatMap((user) => user.assessments.map((a) => a.assessmentName)))]

  // Create CSV header
  let csv = `Name,Email,${assessmentNames.map((name) => `${name} Result,${name} Percentage,${name} Attempts`).join(',')}\n`

  // Create CSV rows
  csv += data
    .map((user) => {
      const { name, email } = user.user
      const assessmentData = assessmentNames
        .map((assessmentName) => {
          const assessment = user.assessments.find((a) => a.assessmentName === assessmentName) || {}
          return `${assessment.assessmentResult || 'N/A'},${assessment.assessmentResultPercent !== null ? `${assessment.assessmentResultPercent}%` : 'N/A'},${
            assessment.attempts !== undefined ? assessment.attempts : 'N/A'
          }`
        })
        .join(',')
      return `${name},${email},${assessmentData}`
    })
    .join('\n')

  return csv
}

function formatPercentage(x, y) {
  return `${((x / y) * 100).toFixed(0)}%`
}

function formatNumberOfNumber(x, y) {
  return `${x} of ${y}`
}

function getTotalPointsFromReport(data) {
  return Object.values(data).reduce((acc, userData) => acc + Object.values(userData.byModule).reduce((a, { points }) => a + points, 0), 0)
}

export default ManageGroup

import { useParams } from 'react-router-dom'
import { Link, Grid, Typography, Card } from '@mui/material'
import MainCard from 'components/MainCard'
import mixpanel from 'mixpanel-browser'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Stack from '@mui/material/Stack'

const getPDFbyModuleId = (moduleId) => {
  const papers = [
    {
      moduleId: '08acb18f-0a90-4c28-8eca-a03e3067465f',
      paperTitle: 'PRSI and USC',
      paperURL: 'https://www.examfly-app.com/files/26.+PRSI+and+Universal+Social+Charge.pdf',
      oneLiner: '',
    },
    {
      moduleId: 'c5ecb496-77cf-4925-88b5-8eeab529042f',
      paperTitle: 'Anti-Avoidance Rules Relating to Foreign-Controlled Private Registered Companies',
      paperURL: 'https://www.examfly-app.com/files/CT+Chp+4_+Anti-Avoidance+Rules+Relating+to+Foreign-Controlled+Private+Registered+Companies.pdf',
      oneLiner: '',
    },
    {
      moduleId: 'c5ecb496-77cf-4925-88b5-8eeab529042f',
      paperTitle: 'Dispositions to and by Companies and Gifts Between Associated Companies',
      paperURL: 'https://www.examfly-app.com/files/CT+Chp+19_+Dispositions+to+and+by+Companies+and+Gifts+Between+Associated+Companies.pdf',
      oneLiner: '',
    },
    {
      moduleId: 'c5ecb496-77cf-4925-88b5-8eeab529042f',
      paperTitle: 'The Rate of Stamp Duty',
      paperURL: 'https://www.examfly-app.com/files/CT+Chp+23_+The+Rate+of+Stamp+Duty.pdf',
      oneLiner: '',
    },
    {
      moduleId: 'c5ecb496-77cf-4925-88b5-8eeab529042f',
      paperTitle: 'Shares & Marketable Securities ',
      paperURL: 'https://www.examfly-app.com/files/CT+Chp+24_+Stampable+Value.pdf',
      oneLiner: '',
    },
    {
      moduleId: "376a2785-b0bd-4cb1-985c-d355c42c2e0c",
      paperTitle: 'Property Tax',
      paperURL: 'https://www.examfly-app.com/files/PT_Ch_15_Property_Tax.pdf',
      oneLiner: '',
    },
    {
      moduleId: "376a2785-b0bd-4cb1-985c-d355c42c2e0c",
      paperTitle: 'Self-Assessment System for Individuals',
      paperURL: 'https://www.examfly-app.com/files/PT_Ch_29_Self_Assessment_System_for_Individuals.pdf',
      oneLiner: ''
    },
    {
      moduleId: "376a2785-b0bd-4cb1-985c-d355c42c2e0c",
      paperTitle: 'PAYE Collection System',
      paperURL: 'https://www.examfly-app.com/files/PT_Ch_30_PAYE_Collection_System.pdf',
      oneLiner: ''
    }
  ]

  return papers.filter((paper) => paper.moduleId === moduleId)
}

const checkIfPDFExists = (moduleId) => {
  return getPDFbyModuleId(moduleId).length > 0
}

const PDFComponent = () => {
  const { moduleId } = useParams()

  return (
    <Grid item xs={12} sm={8}>
      <MainCard sx={{ padding: '16px' }} border={false}>
        <Typography variant="h5">Download Learning Material (PDF)</Typography>
        <Typography variant="body2">
          These downloadable PDFs provide additional guidance on topics required for your Part 2 Exams and helpful tips for exam success. We strongly recommend you read these documents alongside completing the lessons on Examfly.
        </Typography>
        {getPDFbyModuleId(moduleId).map((paper, index) => (
          <Card key={index} sx={{ padding: '16px', margin: '16px 16px 0px 16px' }}>
            <Stack direction="row" spacing={2}>
              <Grid container spacing={2} alignItems="center">
                {/* Text content - occupies 2/3 of the width */}
                <Grid item xs={8}>
                  <Typography variant="h6">{paper.paperTitle}</Typography>
                  <Typography variant="body2">{paper.oneLiner}</Typography>
                </Grid>
                {/* Link content - occupies 1/3 of the width */}
                <Grid item xs={4}>
                  <Link
                    href={paper.paperURL}
                    target="_blank"
                    onClick={(event) => {
                      event.preventDefault()
                      mixpanel.track('button_clicked', {
                        Feature: 'pdf_download',
                        Location: 'module_pdfs',
                        Name: 'PDF Download Link Clicked',
                        Link: paper.paperURL,
                      })
                      window.open(paper.paperURL, '_blank')
                    }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <DownloadForOfflineIcon />
                    <Typography variant="body2" sx={{ marginLeft: '8px' }}>
                      Download {paper.paperTitle}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        ))}
      </MainCard>
    </Grid>
  )
}

export { PDFComponent, checkIfPDFExists }
import { Box, Button, FormLabel, FormControl, FormControlLabel, Radio, RadioGroup, MenuItem, TextField, Typography, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MultiGroupSelect from './multi-group-select'
import PrimaryGroupSelect from './primary-group-select'
function isValidEmail(email) {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
}

const EditUser = ({ onUpdate, user }) => {
  const [email, setEmail] = useState(user.email)
  const [name, setName] = useState(user.name)
  const [loading, setLoading] = useState(false)
  const [changingName, setChangingName] = useState(false)
  const [changingGroups, setChangingGroups] = useState(false)
  const { username, uuid, company_uuid, company_name } = user

  const [groupIDs, setGroupIDs] = useState(user.groups.map((group) => group.group_uuid) || []) // All the groups the user is CURRENTLY assigned to
  const [userGroups, setUserGroups] = useState(user.groups.map((group) => ({ name: group.group_name, uuid: group.group_uuid })) || []) // All the groups the user is CURRENTLY assigned to
  const [availableGroups, setAvailableGroups] = useState([]) // All the company groups a user can be assigned to
  const [checkEmail, setCheckEmail] = useState(false)
  const [newEmailUser, setNewEmailuser] = useState(null)
  const [isSSO, setIsSSO] = useState(null)
  const [haveGroupsChanged, setHaveGroupsChanged] = useState(false)
  const [primaryGroupID, setPrimaryUserGroupID] = useState(user.group_uuid)
  const [companies, setCompanies] = useState([])
  const [companyUUID, setCompanyUUID] = useState(company_uuid)
  useEffect(() => {
    if (companyUUID) {
      axios(`/admin/company/`).then((r) => {
        console.log(r.data)
        setCompanies(r.data)
      })
    }
  }, [companyUUID])

  useEffect(() => {
    if (companyUUID) {
      axios(`/admin/company/${companyUUID}/group/`).then((r) => {
        setAvailableGroups(r.data)
        console.log(r.data)
      })
    }
  }, [companyUUID])

  useEffect(() => {
    if (email && checkEmail) {
      axios({
        method: 'post',
        url: '/user/find',
        data: { email },
      })
        .then((r) => {
          if (r.data.length) {
            const confirmed = window.confirm('A user with that email already exists. Do you want to replace that user?')
            if (confirmed) {
              setNewEmailuser(false)
            } else {
              setLoading(false)
            }
          }
        })
        .catch((e) => setNewEmailuser(false))
    }
  }, [checkEmail, email])

  useEffect(() => {
    if (newEmailUser === false) {
      axios({
        method: 'post',
        url: `/user/${uuid}/change-email`,
        data: {
          newEmail: email,
          email: user.email,
          newIsSSO: isSSO,
        },
      }).then((r) => {
        setLoading(false)
        setTimeout(onUpdate(uuid, { ...user, email }), 1)
      })
    }
  }, [newEmailUser])

  useEffect(() => {
    if (changingGroups) {
      axios({
        method: 'post',
        url: `/user/${uuid}/change-groups`,
        data: {
          companyUUID: companyUUID,
          groupUuids: groupIDs,
          primaryGroupUuid: primaryGroupID,
        },
      }).then((r) => {
        setChangingGroups(false)
        setHaveGroupsChanged(false)
        setTimeout(onUpdate(uuid, { ...user }), 1)
      })
    }
  }, [changingGroups, groupIDs, primaryGroupID])

  useEffect(() => {
    if (changingName) {
      axios({
        method: 'post',
        url: `/user/${uuid}/basic-data`,
        data: {
          name,
        },
      }).then((r) => {
        setChangingName(false)
        setTimeout(onUpdate(uuid, { ...user, name }), 1)
      })
    }
  }, [changingName])

  return (
    <Box
      sx={{
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        padding: '40px',
        minWidth: '850px',
      }}
    >
      <Typography>
        <Typography component="span" sx={{ fontWeight: 'bold' }}>
          Username:
        </Typography>{' '}
        {username}
      </Typography>
      <Typography>
        <Typography component="span" sx={{ fontWeight: 'bold' }}>
          Company:
        </Typography>{' '}
        {company_name}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #F2F6F7', paddingBottom: '20px' }}>
        <TextField sx={{ marginTop: '20px' }} label="Name" variant="outlined" name="name" value={name} onChange={(e) => setName(e.target.value)} />
        <Button disabled={name === user.name || changingName} onClick={() => setChangingName(true)}>
          Save Name Change
        </Button>
      </Box>

      <Box sx={{ alignItems: 'left', borderBottom: '1px solid #F2F6F7', paddingTop: '20px', paddingBottom: '20px' }}>
        <Box>
          {companies.length > 0 && (
            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <TextField
                fullWidth
                label="Company"
                name="company"
                value={companyUUID}
                select
                onChange={(e) => {
                  const company = companies.find((c) => c.uuid === e.target.value)
                  console.log(company)
                  setCompanyUUID(company.uuid)
                  setGroupIDs([])
                  setUserGroups([])
                }}
              >
                {companies.map((company) => (
                  <MenuItem key={company.uuid} value={company.uuid}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
        </Box>
        <Box>
          {availableGroups && (
            <MultiGroupSelect
              availableGroups={availableGroups}
              selectedGroupIDs={groupIDs}
              onChange={(groupIds) => {
                setGroupIDs(groupIds)
                setUserGroups(availableGroups.filter((group) => groupIds.includes(group.uuid)))
                setHaveGroupsChanged(true)
              }}
            />
          )}
          {availableGroups && (
            <PrimaryGroupSelect
              userGroups={userGroups}
              selectedGroupIDs={groupIDs}
              primaryUserGroupID={primaryGroupID}
              onChange={(event) => {
                setPrimaryUserGroupID(event.target.value)
                setHaveGroupsChanged(true)
              }}
            />
          )}
          <Button disabled={!haveGroupsChanged} onClick={() => setChangingGroups(true)}>
            Save Group Changes
          </Button>
        </Box>
      </Box>
      <TextField sx={{ marginTop: '20px' }} label="Email" variant="outlined" name="email" value={email} onChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))} />
      {email !== user.email && (
        <>
          <FormLabel id="SSO-label">Will this user use SSO with the new email?</FormLabel>
          <RadioGroup aria-labelledby="SSO-label">
            <FormControlLabel selected={isSSO === true} onChange={() => setIsSSO(true)} value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel selected={isSSO === false} onChange={() => setIsSSO(false)} value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </>
      )}
      {newEmailUser === null && isSSO !== null && (
        <Button
          disabled={email === user.email || !isValidEmail(email)}
          onClick={() => {
            setCheckEmail(true)
            setLoading(true)
          }}
        >
          {loading && <CircularProgress />}
          Change email
        </Button>
      )}
    </Box>
  )
}

export default EditUser

import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormLabel,
  FormControlLabel,
  Input,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StarIcon from '@mui/icons-material/Star'
import CopyToClipboardButton from '../components/copy-to-clipboard-button'
import axios from 'axios'

import ModulesList from './group-module-list'
import AssessmentsList from './group-assessment-list'
import CreateUser from '../users/create-user'
import { UserList } from './user-list'

const formatDate = (dateString) => {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}`
}

const EditableGroup = ({ selectedCompanyID, onDelete, onUpdate, name, uuid, company_name, start_ts, end_ts }) => {
  const [loading, setLoading] = useState(true)
  const [pointsLoading, setPointsLoading] = useState(true)
  const [points, setPoints] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const [users, setUsers] = useState([])
  const [modules, setModules] = useState([])
  const [assessments, setAssessments] = useState([])
  const [addUser, setAddUser] = useState(false)
  useEffect(() => {
    if (deleting) {
      const deleteUsers = window.confirm('The users of this group will be deleted! Are you sure?')
      if (deleteUsers) {
        Promise.all([
          axios({
            url: '/user/bulk',
            method: 'delete',
            data: {
              uuids: users.map((u) => u.uuid),
            },
          }),
          axios({
            url: `/group/`,
            method: 'delete',
            data: {
              groupId: uuid,
            },
          }),
        ]).then((r) => {
          setDeleting(false)
          onDelete(uuid)
        })
      }
    }
  }, [deleting])

  const handleChange = (uuid) => (event, isExpanded) => {
    if (loading) {
      fetchUsersInGroup()
    }
    if (pointsLoading) {
      axios(`/reports/points/group/${uuid}`).then((r) => {
        setPoints(r.data.total)
        setPointsLoading(false)
      })
    }
  }

  const fetchUsersInGroup = () => {
    setLoading(true)
    axios(`/group/${uuid}`).then((group) => {
      setUsers(group.data.users)
      setModules(group.data.modules)
      setAssessments(group.data.assessments)
      setLoading(false)
    })
  }

  return (
    <Accordion sx={{ flexGrow: 1, marginLeft: 'auto' }} onChange={handleChange(uuid)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {name} - {modules.length > 0 ? modules.length : 'Expand for info on'} Modules - {users.length > 0 ? `${users.length} Users` : ''}
          <Typography component="span" sx={{ backgroundColor: '#F2F6F7', display: 'inline-flex', padding: '10px', fontWeight: 'bold' }}>
            <StarIcon sx={{ '&&': { color: 'orange' } }} /> {typeof points === 'number' ? `${points} Total points` : 'Expand group for points'}
          </Typography>
        </Typography>
        <Typography sx={{ marginLeft: 'auto' }}>
          <Link href={`/group/${uuid}`}>Go to report</Link>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h3">Group details</Typography>

        <Box>
          <Typography variant="">
            <CopyToClipboardButton content={uuid} />
            UUID: {uuid}
          </Typography>
          <Typography>Company: {company_name}</Typography>
          <Typography>Start Date: {formatDate(start_ts)}</Typography>
          <Typography>End Date: {formatDate(end_ts)}</Typography>
        </Box>

        <Box sx={{ borderBottom: '2px solid #F2F6F7', display: 'flex', flexDirection: 'column' }}></Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={{ borderBottom: '2px solid #F2F6F7' }}>
              <ModulesList
                addedModule={(module) => setModules([...modules, module])}
                removedModule={(uuid) => setModules(modules.filter((m) => m.uuid !== uuid))}
                groupId={uuid}
                modules={modules}
              />
            </Box>
            <Box sx={{ borderBottom: '2px solid #F2F6F7' }}>
              <AssessmentsList
                addedAssessment={(assessment) => setAssessments([...assessments, assessment])}
                removedAssessment={(uuid) => setAssessments(assessments.filter((m) => m.uuid !== uuid))}
                groupId={uuid}
                assessments={assessments}
              />
            </Box>
            <Box sx={{ marginBottom: '20px' }}>
              <Typography variant="h3">Users:</Typography>
              <Button onClick={() => setAddUser(true)}>Add Single New User</Button>
            </Box>
            <Modal open={addUser} onClose={() => setAddUser(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CreateUser
                  onAdd={() => {
                    setAddUser(false)
                    fetchUsersInGroup()
                  }}
                  groupId={uuid}
                  companyID={selectedCompanyID}
                />
              </Box>
            </Modal>
            <CreateFromCsv fetchUsersInGroup={fetchUsersInGroup} groupId={uuid} companyID={selectedCompanyID} />
          </>
        )}
        <UserList
          onDelete={(uuid) => {
            setUsers(users.filter((u) => u.uuid !== uuid))
          }}
          userList={users}
          onUpdate={(uuid, newData) => {
            setUsers(users.map((u) => (u.uuid === uuid ? newData : u)))
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

function fromCSV(csvValue) {
  return csvValue
    .split(/\r?\n/)
    .slice(1)
    .map((l) => {
      const [name, email] = l.split(',')
      return { name, email }
    })
}

const CreateFromCsv = ({ fetchUsersInGroup, companyID, groupId }) => {
  const [users, setUsers] = useState('')
  const [savingUsers, setSavingUsers] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [createdUsers, setCreatedUsers] = useState([])
  const [existingUsers, setExistingUsers] = useState([])
  const [existingUsernames, setExistingUsernames] = useState([])
  const [skippedUsers, setSkippedUsers] = useState([])
  const [isSSO, setIsSSO] = useState(null)

  useEffect(() => {
    if (savingUsers) {
      axios({
        url: '/user/bulk/create',
        method: 'post',
        data: {
          isSSO,
          company_uuid: companyID,
          group_uuid: groupId,
          users,
        },
      }).then((r) => {
        setSavingUsers(false)

        setCreatedUsers(r.data.created)
        setExistingUsers(r.data.existing)
        setExistingUsernames(r.data.existingUsernames)
        setSkippedUsers(r.data.skippedUsers)

        setOpenModal(true)

        // fetchUsersInGroup()
      })
    }
  }, [savingUsers])

  return (
    <Box sx={{ backgroundColor: '#F2F6F7', padding: '20px' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Create or Add users from CSV</Typography>
      <Box>
        <Input type="file" inputProps={{ accept: '.csv' }} onChange={(e) => e.target.files[0].text().then((r) => setUsers(fromCSV(r)))} />
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <FormLabel id="SSO-label">Are they SSO users?</FormLabel>
        <RadioGroup aria-labelledby="SSO-label">
          <FormControlLabel selected={isSSO === true} onChange={() => setIsSSO(true)} value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel selected={isSSO === false} onChange={() => setIsSSO(false)} value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </Box>
      <Button disabled={isSSO === null || savingUsers || users === ''} onClick={() => setSavingUsers(true)}>
        {savingUsers && (
          <>
            <CircularProgress />
            Creating users...
          </>
        )}
        Create/Add users to group
      </Button>
      {openModal && (
        <GroupUploadFeedbackModal
          open={openModal}
          createdUsers={createdUsers}
          existingUsers={existingUsers}
          existingUsernames={existingUsernames}
          skippedUsers={skippedUsers}
          onClose={() => {
            setOpenModal(false)
            fetchUsersInGroup()
          }}
        />
      )}
    </Box>
  )
}

const GroupUploadFeedbackModal = ({ open, onClose, createdUsers, existingUsers, existingUsernames, skippedUsers }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h3">Group Upload Feedback</Typography>
        <Typography variant="h4">Created Users:</Typography>
        <Typography>Number of created Users: {createdUsers.length}</Typography>
        <Typography>Number of existing Users: {existingUsers.length}</Typography>
        <Divider />

        {existingUsers.length > 0 && (
          <>
            <Typography variant="h4">Existing Users:</Typography>
            <Typography>These users are already in a group and now have this additional group.</Typography>
            {existingUsers.map((u) => (
              <Typography key={u.uuid}>{u.email}</Typography>
            ))}
          </>
        )}
        <Divider />

        {existingUsernames.length > 0 && (
          <>
            <Typography variant="h4">Existing Usernames:</Typography>
            <Typography>Should be the same as Existing user above.</Typography>
            {existingUsernames.map((u) => (
              <Typography key={u.uuid}>
                {u.username} : {u.email}
              </Typography>
            ))}
          </>
        )}
        <Divider />

        {skippedUsers.length > 0 && (
          <>
            <Typography variant="h4" color="error" sx={{ fontWeight: 'bold' }}>
              Skipped Users:
            </Typography>
            <Typography>Number of skipped Users: {skippedUsers.length}</Typography>
            <Typography>User emails that already exist in another company</Typography>
            <Typography>Skipped Users:</Typography>
            {skippedUsers.map((u) => (
              <Typography key={u.uuid}>{u.email}</Typography>
            ))}
          </>
        )}
      </Box>
    </Modal>
  )
}

export default EditableGroup

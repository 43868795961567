import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button, Card, CardHeader, CardContent, CircularProgress, Stack, Divider, List, ListItem, TextField, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Cookies from 'js-cookie'
import axios from 'axios'

import EditableGroup from './edit-group'

const Groups = (props) => {
  const [loading, setLoading] = useState(true)
  const groupsSelectedCompanyIDCookieKey = 'admin_panel_groups_selected_company_id'
  const [selectedCompanyID, setSelectedCompanyID] = useState(Cookies.get(groupsSelectedCompanyIDCookieKey) || null)

  const [activeFilter, setActiveFilter] = useState('true')
  const [groups, setGroups] = useState([])
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    axios('/admin/company').then((r) => {
      setCompanies(r.data)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (selectedCompanyID) {
      setLoading(true)
      let apiUrl = `/admin/company/${selectedCompanyID}/group?is_active=${activeFilter}`
      if (selectedCompanyID === 'ALL_COMPANIES_GROUPS') {
        apiUrl = `/admin/company/group?is_active=${activeFilter}`
      }
      axios(apiUrl).then((r) => {
        setGroups(r.data)
        setLoading(false)
      })
    }
  }, [selectedCompanyID, activeFilter])

  const handleSelectedCompanyIDChange = (value) => {
    setSelectedCompanyID(value)
    Cookies.set(groupsSelectedCompanyIDCookieKey, value)
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Typography sx={{ marginBottom: '20px' }} variant="h4">
        Groups
      </Typography>
      <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
      <Stack direction="row" spacing={2} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />}>
        {!loading && <CompanyFilter filter={selectedCompanyID} setFilter={(c) => handleSelectedCompanyIDChange(c)} companies={companies} />}
        {!loading && <ActiveFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />}
      </Stack>
      <Divider sx={{ marginTop: '10px' }} />
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {groups.map((group) => (
            <ListItem key={group.uuid}>
              <EditableGroup
                key={group.uuid}
                selectedCompanyID={selectedCompanyID}
                onDelete={(uuid) => setGroups(groups.filter((g) => g.uuid !== uuid))}
                onUpdate={(data) =>
                  setGroups(
                    groups.map((g) => {
                      if (g.uuid === data.uuid) {
                        return data
                      } else {
                        return g
                      }
                    })
                  )
                }
                {...group}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  )
}

const ActiveFilter = ({ activeFilter, setActiveFilter }) => {
  const handleChange = (event) => {
    setActiveFilter(event.target.value)
  }
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Filter:</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Active</InputLabel>

          <Select defaultValue="" labelId="demo-simple-select-label" id="demo-simple-select" sx={{ minWidth: '200px' }} value={activeFilter || 'active'} onChange={handleChange}>
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

const CompanyFilter = ({ companies, filter, setFilter }) => {
  const [companyFilter, setCompanyFilter] = useState('Choose Company')

  const ALL_COMPANIES_GROUPS = 'ALL_COMPANIES_GROUPS'
  const handleChange = (event) => {
    setCompanyFilter(event.target.value)
    setFilter(event.target.value)
  }

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Company Filter:</Typography>
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Company</InputLabel>

          <Select defaultValue="" sx={{ minWidth: '200px' }} labelId="demo-simple-select-label" id="demo-simple-select" value={filter || ''} onChange={handleChange}>
            <MenuItem key={ALL_COMPANIES_GROUPS} value={ALL_COMPANIES_GROUPS}>
              All Companies
            </MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.uuid} value={company.uuid}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

export default Groups

import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material'

const PrimaryGroupSelect = ({ primaryUserGroupID, onChange, userGroups }) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <InputLabel id="primary-group">Primary Group</InputLabel>
      <Select
        labelId="primary-group"
        label="Primary Group"
        value={primaryUserGroupID}
        onChange={onChange}
        renderValue={(selected) => {
          const group = userGroups.find((group) => group.uuid === selected)
          return group ? group.name : null
        }}
        autoWidth
      >
        {userGroups.map(({ name, uuid }) => (
          <MenuItem key={`primary-group-option-${uuid}`} value={uuid}>
            <Checkbox checked={primaryUserGroupID === uuid} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PrimaryGroupSelect
